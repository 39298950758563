<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <b-card class="h-100">
          <div class="text-center mt-2">
            <b-avatar size="80" variant="primary">
              <feather-icon icon="UserIcon" size="40" />
            </b-avatar>

            <p class="mb-0 mt-2">
              <b
                >{{ customer.customer_name }} {{ customer.customer_surname }}</b
              >
            </p>

            <hr class="mt-3" />
          </div>

          <div class="py-2">
            <div class="mb-1">
              <b>Telefono</b>
              <br>
              <span>{{ customer.customer_phone }}</span>
            </div>
            <div>
              <b>Email</b>
              <br>
              <span>{{ customer.customer_email }}</span>
            </div>
            <!-- <div>
              <b>Data:</b>
              <span class="ml-1">25/12/2021</span>
            </div>

            <hr class="mt-2" />
          </div>

          <div class="flex-grow-1">
            <div>
              <b>Tessera:</b>
              <span class="ml-1">Dc0578-566</span>
            </div> -->
          </div>
        </b-card>
      </b-col>

      <b-col md="9">
        <b-card class="h-100 mb-0">
          <b-container>
            <b-row>
              <b-col md="12" class="mb-2">
                <h5 class="mb-0">Dati Personali</h5>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nome" label-for="first_name">
                  <b-form-input
                    readonly
                    plaintext
                    id="first_name"
                    v-model="customer.customer_name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Cognome" label-for="last_name">
                  <b-form-input
                    readonly
                    plaintext
                    id="last_name"
                    v-model="customer.customer_surname"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Data di nascita" label-for="birthdate">
                  <b-form-input
                    readonly
                    plaintext
                    id="birthdate"
                    v-model="customer.customer_birthdate"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Luogo di nascita" label-for="birthplace">
                  <b-form-input
                    readonly
                    plaintext
                    id="birthplace"
                    v-model="customer.customer_birthcity"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Codice fiscale" label-for="fiscal_code">
                  <b-form-input
                    readonly
                    plaintext
                    id="fiscal_code"
                    v-model="customer.customer_taxcode"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="6">
                <b-form-group label="Indirizzo" label-for="address">
                  <b-form-input
                    readonly
                    plaintext
                    id="address"
                    v-model="customer.customer_address"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Città" label-for="city">
                      <b-form-input
                        readonly
                        plaintext
                        id="city"
                        v-model="customer.customer_city"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Provincia" label-for="province">
                      <b-form-input
                        readonly
                        plaintext
                        id="province"
                        v-model="customer.customer_province"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Cap" label-for="cap">
                      <b-form-input
                        readonly
                        plaintext
                        id="cap"
                        v-model="customer.customer_zipcode"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-col>
            </b-row>
            <b-row>
                  <b-col md="6">
                    <b-form-group label="Codice tessera" label-for="cardnumber">
                      <b-form-input 
                        readonly
                        plaintext
                        id="cardnumber"
                        v-model="customer.customer_cardnumber"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BAvatar,
  BContainer,
  BCard,
  BBadge,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

import { Requests } from "@/api/requests";

export default {
  components: {
    BAvatar,
    BContainer,
    BCard,
    BCol,
    BRow,
    BBadge,
    BButton,
    BFormGroup,
    BFormInput,
  },

  data: () => ({
    customer: {},
  }),

  created() {
    this.customerDetails();
  },

  methods: {
    async customerDetails() {
      try {
        const response = await Requests.customerDetails(
          "customer_id=" + this.$route.params.id
        );
        this.customer = response.account_details.payload;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  display: flex;
  flex-direction: column;
}
</style>
